
=c
  @each $name, $value in $colors
    &.color-#{ $name }
      color: #{ $value }

    &.color-#{ $name }-hover
      &:hover
        color: #{ $value }

    &.bg-#{ $name }
      background: #{ $value }

    &.bg-#{ $name }-hover
      &:hover
        background: #{ $value }

*
  +c
