body
  font-family: Inter,Helvetica,sans-serif

$colors: ( "main": #f9f9f9, "white": #fff, "gray": #9fa6bb, "gray-800": #252F4A, "gray-900": #071437, "light": #f9f9f9, "primary": #1b84ff, "primary-active": #056ee9, "cyan": #66d7d1, "yellow": #f9c784, "red": #e4572e, "light-blue": #acd7ec, "green": #4cc74a, "none": transparent )

=colors
  @each $name, $value in $colors
    --color-#{ $name }: #{ $value }

=sizes
  @for $i from 2 through 10
    --x#{ $i }: calc( var( --x ) * #{ $i } )

    --x#{ $i * 7 }: calc( var( --x ) * #{ $i * 7 } )

\:root
  --x: 7px
  +sizes // 2 to 10, 14 to 70 step by 7
  +colors
