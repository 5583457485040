$borderStyleList: ( "dashed", "dotted", "solid" )

=styles
  @each $style in $borderStyleList
    &.border-#{ $style }
      border-style: #{ $style }

=weights
  @for $i from 1 through 7
    &.bw-x#{ $i }
      border-width: #{ $i }px

=colors
  @each $name, $value in $colors
    &.bc-#{ $name }
      border-color: var( --color-#{ $name } )

=radius
  @for $i from 2 to 10
    &.br-x#{ $i }
      border-radius: var( --x#{ $i } )

.border
  border: 1px solid transparent

  +styles

  +weights

  +colors

  &.radius
    border-radius: var( --x )

    +radius

  &.shadow
    border: 1px solid #f1f1f4
    box-shadow: 0px 3px 4px 0px rgba( 0, 0, 0, 0.03 )
