=gaps
  @for $i from 2 through 10
    &.g#{ $i }
      gap: var( --x#{ $i } )

    &.g#{ $i * 7 }
      gap: var( --x#{ $i * 7 } )

=paddings
  @for $i from 2 through 10
    &.p#{ $i }
      padding: var( --x#{ $i } )

    &.p#{ $i * 7 }
      padding: var( --x#{ $i * 7 } )

    &.pt#{ $i }
      padding-top: var( --x#{ $i } )

    &.pr#{ $i }
      padding-right: var( --x#{ $i } )

    &.pb#{ $i }
      padding-bottom: var( --x#{ $i } )

    &.pl#{ $i }
      padding-left: var( --x#{ $i } )

    &.pt#{ $i * 7 }
      padding-top: var( --x#{ $i * 7 } )

    &.pr#{ $i * 7 }
      padding-right: var( --x#{ $i * 7 } )

    &.pb#{ $i * 7 }
      padding-bottom: var( --x#{ $i * 7 } )

    &.pl#{ $i * 7 }
      padding-left: var( --x#{ $i * 7 } )


=margins
  @for $i from 2 through 10
    &.m#{ $i }
      margin: var( --x#{ $i } )

    &.m#{ $i * 7 }
      margin: var( --x#{ $i * 7 } )

    &.mt#{ $i }
      margin-top: var( --x#{ $i } )

    &.mr#{ $i }
      margin-right: var( --x#{ $i } )

    &.mb#{ $i }
      margin-bottom: var( --x#{ $i } )

    &.ml#{ $i }
      margin-left: var( --x#{ $i } )

    &.mt#{ $i * 7 }
      margin-top: var( --x#{ $i * 7 } )

    &.mr#{ $i * 7 }
      margin-right: var( --x#{ $i * 7 } )

    &.mb#{ $i * 7 }
      margin-bottom: var( --x#{ $i * 7 } )

    &.ml#{ $i * 7 }
      margin-left: var( --x#{ $i * 7 } )

#root
  width: 100%
  height: 100vh

  background: var( --color-main )

  display: flex

  .hs
    display: flex
    flex-direction: column

  .vs
    display: flex

  .one
    flex: 1

  .two
    flex: 2

  .three
    flex: 3

  .four
    flex: 4

  .five
    flex: 5

  .six
    flex: 6

  .seven
    flex: 7

  .space-between
    justify-content: space-between

  .flex-end
    justify-content: flex-end

  .align-center
    align-items: center

  .justify-center
    justify-content: center

  .wrap
    flex-wrap: wrap

  .gap
    gap: var( --x )

    +gaps

  .padding

    +paddings

    &.p-sides
      padding-top: 0
      padding-bottom: 0

  .margin

    +margins

    &.m-sides
      margin-top: 0
      margin-bottom: 0

  .overflow-y
    overflow-y: auto
