=sizes

  @for $i from 2 through 10
    &.f#{ $i }
      font-size: var( --x#{ $i } )

    &.f#{ $i * 7 }
      font-size: var( --x#{ $i * 7 } )

    @for $j from 2 through 10
      &.f#{ $i }-#{ $j }
        font-size: calc( var( --x ) * #{ $i + 0.1 * $j } )

=weights
  $i: 100
  @while $i <= 900
    &.fw#{ $i }
      font-weight: #{ $i }

    $i: $i + 100

*
  +sizes
  +weights
