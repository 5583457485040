=wh
  @for $i from 2 through 10
    &.w#{ $i }
      width: var( --x#{ $i } )

    &.h#{ $i }
      height: var( --x#{ $i } )

    &.w#{ $i * 7 }
      width: var( --x#{ $i * 7 } )

    &.h#{ $i * 7 }
      height: var( --x#{ $i * 7 } )

*
  +wh
